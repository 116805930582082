import React from 'react';

const QnA = () => {
  return (
    <div className="qna">
      <h1>Questions & Answers</h1>
      <p>Ask questions or browse through existing ones.</p>
      {/* Add Q&A functionality */}
    </div>
  );
};

export default QnA;