import React from 'react';
import { PenLine } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const FloatingActionButton = ({ page }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    // 명시적인 경로 사용
    if (page === 'social') {
      navigate('/write/social');
    } else if (page === 'info') {
      navigate('/write/info');
    }
  };

  return (
    <button 
      onClick={handleClick}
      className="fixed bottom-20 right-4 w-14 h-14 bg-[#BC2649] rounded-full shadow-lg flex items-center justify-center z-50 hover:bg-[#a32041] transition-colors"
    >
      <PenLine className="w-6 h-6 text-white" />
    </button>
  );
};

export default FloatingActionButton;