import React from 'react';
import PostItem from '../components/PostItem';
import FloatingActionButton from '../components/FloatingActionButton';

const Social = () => {
  const posts = [
    {
      id: 1,
      image: "https://picsum.photos/70/40",
      title: "슬기로운 대학생활",
      isHot: true,
      likes: 12,
      comments: 3,
      time: "1일 전",
      author: "관리자",
      authorIcon: "https://via.placeholder.com/12x12"
    },
    {
      id: 2,
      image: "https://via.placeholder.com/70x40",
      title: "아파트 불법전단 뎐 중학생 소녀가...",
      isHot: true,
      likes: 12,
      comments: 3,
      time: "1일 전",
      author: "관리자",
      authorIcon: "https://via.placeholder.com/12x12"
    },
    // ... 더 많은 게시글 데이터
  ];

  return (
    <div className="flex flex-col h-screen">
      {/* Header */}
      <div className="p-4 flex justify-between items-center bg-white">
        <h1 className="text-2xl font-bold">Social</h1>
        <button className="p-2">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <circle cx="11" cy="11" r="8"/>
            <line x1="21" y1="21" x2="16.65" y2="16.65"/>
          </svg>
        </button>
      </div>
      
      {/* Scrollable Content Area */}
      <div className="flex-1 overflow-y-auto pb-16">
        {posts.map(post => (
          <PostItem key={post.id} post={post} />
        ))}
      </div>

      {/* Floating Action Button */}
      <FloatingActionButton page="social" />
    </div>
  );
};

export default Social;