// import React from 'react';
// import styled from 'styled-components';
// import { ReactComponent as KakaoIcon } from '../assets/icons/kakao.svg';
// import { ReactComponent as GoogleIcon } from '../assets/icons/google.svg';
// import { ReactComponent as FacebookIcon } from '../assets/icons/facebook.svg';
// import { ReactComponent as AppleIcon } from '../assets/icons/apple.svg';
// import { ReactComponent as KoreaMateTextLogo } from '../assets/icons/KoreaMateTextLogo.svg';
// import { ReactComponent as KoreaMateSymbolLogo } from '../assets/icons/KoreaMateSymbolLogo.svg';



// const OnboardingContainer = styled.div`
//   width: 100%;
//   height: 100vh;
//   background: white;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: space-between;
//   padding: 40px 20px;
//   box-sizing: border-box;
// `;

// const TopSection = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;

// const LoginSection = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;

// // const MonthlyText = styled.div`
// //   align-self: flex-end;
// //   color: black;
// //   font-size: 12px;
// //   font-family: 'Pretendard', sans-serif;
// //   font-weight: 400;
// //   margin-bottom: 20px;
// // `;

// const LoginDivider = styled.div`
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-bottom: 20px;
// `;

// const DividerLine = styled.div`
//   width: 92px;
//   height: 1px;
//   background: #EEEEEE;
// `;

// const DividerText = styled.div`
//   margin: 0 10px;
//   color: black;
//   font-size: 12px;
//   font-family: 'Pretendard', sans-serif;
//   font-weight: 400;
// `;

// const SocialLoginContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   width: 260px;
//   margin-bottom: 20px;
// `;

// const SocialLoginButton = styled.button`
//   width: 50px;
//   height: 50px;
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: ${props => props.bgColor || 'transparent'};
//   border: ${props => props.border || 'none'};
//   svg {
//     width: 24px;
//     height: 24px;
//   }
// `;


// const StartButton = styled.button`
//   width: 100%;
//   max-width: 324px;
//   height: 42px;
//   background: #BC2649;
//   border-radius: 5px;
//   border: none;
//   color: white;
//   font-size: 16px;
//   font-family: 'Pretendard', sans-serif;
//   font-weight: 500;
//   cursor: pointer;
// `;

// const TextLogo = styled.div`
//   width: 225px;
//   margin-bottom: 40px;
//   svg {
//     width: 100%;
//     height: auto;
//   }
// `;

// const SymbolLogo = styled.div`
//   width: 127.56px;
//   svg {
//     width: 100%;
//     height: auto;
//   }
// `;
// const Onboarding = () => {
//   return (
//     <OnboardingContainer>
//       <TopSection>
//         <TextLogo>
//           <KoreaMateTextLogo />
//         </TextLogo>
//         <SymbolLogo>
//           <KoreaMateSymbolLogo />
//         </SymbolLogo>
//       </TopSection>
//       <LoginSection>
//         <LoginDivider>
//           <DividerLine />
//           <DividerText>SNS 계정으로 로그인</DividerText>
//           <DividerLine />
//         </LoginDivider>
//         <SocialLoginContainer>
//           <SocialLoginButton bgColor="#FEE500">
//             <KakaoIcon />
//           </SocialLoginButton>
//           <SocialLoginButton bgColor="#1877F2">
//             <FacebookIcon />
//           </SocialLoginButton>
//           <SocialLoginButton bgColor="white" border="1px solid #E2E2E2">
//             <GoogleIcon />
//           </SocialLoginButton>
//           <SocialLoginButton bgColor="#000000">
//             <AppleIcon />
//           </SocialLoginButton>
//         </SocialLoginContainer>
//         <StartButton>바로 사용하기</StartButton>
//       </LoginSection>
//     </OnboardingContainer>
//   );
// };

// export default Onboarding;

// import React, { useState } from 'react';
// import { ChevronLeft } from 'lucide-react';

// const OnboardingPage = () => {
//   const [currentSlide, setCurrentSlide] = useState(0);

//   return (
//     <div className="flex flex-col h-screen bg-white">
//       {/* Header */}
//       <div className="p-4 border-b">
//         <div className="flex items-center">
//           <ChevronLeft className="w-6 h-6" />
//           <span className="ml-2">전문가 회원</span>
//         </div>
//       </div>

//       {/* Main Content */}
//       <div className="flex-1 p-4">
//         {/* Title Section */}
//         <div className="mb-6">
//           <h1 className="text-3xl font-bold mb-2">Q&A</h1>
//           <h2 className="text-xl font-semibold mb-4">전문가 매칭 서비스</h2>
//           <p className="text-gray-600">
//             문제에 대해 전문가가 실질적인 해결책을 제공해요.
//           </p>
//         </div>

//         {/* Card */}
//         <div className="bg-gray-50 rounded p-4 mb-8">
//           <div className="flex items-center mb-4">
//             <div className="w-8 h-8 bg-gray-200 rounded-full" />
//             <div className="ml-2">
//               <div>관리자</div>
//               <div className="text-sm text-gray-500">2월 2,5일</div>
//             </div>
//             <div className="ml-auto text-red-500">★</div>
//           </div>
//           <p className="text-gray-700 whitespace-pre-line">
//             -비자부는 합법적인 이주를 전치하고 촉진하며 지원하기 위하여 다양한 정책적 지원방안을 시행하고 있습니다.{'\n\n'}
//             -2013년부터 교수/연구원 등 전문인력, 의료관광객, 단체관광객 등 일부 외국인의 경우,
//           </p>
//         </div>

//         {/* Slide Indicators */}
//         <div className="flex justify-center gap-2 mb-8">
//           {[0, 1, 2].map((idx) => (
//             <div
//               key={idx}
//               className={`w-2 h-2 rounded-full cursor-pointer ${
//                 currentSlide === idx ? 'bg-[#BC2649]' : 'bg-gray-300'
//               }`}
//               onClick={() => setCurrentSlide(idx)}
//             />
//           ))}
//         </div>

//         {/* Bottom Buttons */}
//         <div className="space-y-2">
//           <div className="flex gap-2">
//             <button className="flex-1 py-3 bg-white border border-gray-300 rounded">
//               로그인
//             </button>
//             <button className="flex-1 py-3 bg-[#BC2649] text-white rounded">
//               회원가입
//             </button>
//           </div>
//           <button className="w-full py-3 bg-white border border-gray-300 rounded">
//             바로 시작하기
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default OnboardingPage;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import QAImage from '../assets/images/qa_detail.png';
import InfoImage from '../assets/images/info_detail.png';
import SocialImage from '../assets/images/social_detail.png';

const Onboarding = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();

  const slides = [
    {
      category: 'Q&A',
      title: '전문가 매칭 서비스',
      description: '문제에 대해 전문가가 실질적인 해결책을 제공해요.',
      image: QAImage
    },
    {
      category: 'Information',
      title: '최신 정보제공',
      description: '한국어 능력이 부족해도\n필요한 정보를 쉽게 얻을 수 있어요.',
      image: InfoImage
    },
    {
      category: 'Social',
      title: '커뮤니티',
      description: '다른 외국인들과 자유롭게 소통해보세요!',
      image: SocialImage
    }
  ];

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  const handleLogin = () => {
    navigate('/login', { state: { redirectTo: '/social' } });
  };

  const handleSignup = () => {
    navigate('/signup', { state: { redirectTo: '/social' } });
  };

  const handleStart = () => {
    navigate('/social');
  };

  return (
    <div className="relative w-full h-screen max-w-[420px] mx-auto bg-white">
      {/* 상단 텍스트 영역 */}
      <div className="px-8 pt-[73px]">
        <div className="flex flex-col items-center">
          <div style={{
            color: '#A6A6A6',
            fontSize: '14px',
            fontFamily: 'Pretendard',
            fontWeight: '600',
            lineHeight: '20px',
            textAlign: 'center',
            wordWrap: 'break-word'
          }}>
            {slides[currentSlide].category}
          </div>
          <div style={{
            color: 'black',
            fontSize: '24px',
            fontFamily: 'Pretendard',
            fontWeight: '600',
            lineHeight: '35px',
            textAlign: 'center',
            wordWrap: 'break-word'
          }}>
            {slides[currentSlide].title}
          </div>
        </div>

        <div style={{
          color: 'black',
          fontSize: '14px',
          fontFamily: 'Pretendard',
          fontWeight: '500',
          lineHeight: '18px',
          textAlign: 'center',
          wordWrap: 'break-word',
          marginTop: '28px'
        }}>
          {slides[currentSlide].description}
        </div>
      </div>

      {/* 고정된 이미지 영역 */}
      <div className="absolute left-0 right-0 top-[230px] flex justify-center">
        <div className="relative">
          <img 
            src={slides[currentSlide].image}
            alt="Content preview"
            style={{
              width: '238px',
              height: '490px',
              borderRadius: '5px',
              border: '4px solid #292929',
              boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.10)'
            }}
          />
          <div className="absolute left-0 right-0 bottom-0 h-[300px] bg-gradient-to-b from-transparent to-white" />
        </div>
      </div>

      {/* Pagination Dots */}
      <div className="absolute bottom-[140px] left-0 right-0">
        <div className="flex justify-center gap-[6px]">
          {slides.map((_, index) => (
            <button
              key={index}
              onClick={() => handleDotClick(index)}
              style={{
                width: '6px',
                height: '6px',
                background: currentSlide === index ? '#BC2649' : '#A6A6A6',
                border: 'none',
                padding: 0,
                cursor: 'pointer'
              }}
            />
          ))}
        </div>
      </div>

      {/* Bottom Buttons */}
      <div className="absolute bottom-0 left-0 right-0 p-4 bg-white">
        <div className="flex justify-between gap-3 mb-2">
          <button
            onClick={handleLogin}
            style={{
              flex: 1,
              height: '40px',
              padding: '8px 16px',
              border: '1px solid #DDDDDD',
              borderRadius: '5px',
              color: '#292929',
              fontSize: '16px',
              fontFamily: 'Pretendard',
              fontWeight: '400',
              lineHeight: '24px',
              textAlign: 'center'
            }}
          >
            로그인
          </button>
          <button
            onClick={handleSignup}
            style={{
              flex: 1,
              height: '40px',
              padding: '8px 16px',
              background: '#BC2649',
              borderRadius: '5px',
              color: 'white',
              fontSize: '16px',
              fontFamily: 'Pretendard',
              fontWeight: '400',
              lineHeight: '24px',
              textAlign: 'center'
            }}
          >
            회원가입
          </button>
        </div>
        <button
          onClick={handleStart}
          style={{
            width: '100%',
            height: '40px',
            padding: '8px 16px',
            border: '1px solid #DDDDDD',
            borderRadius: '5px',
            color: '#292929',
            fontSize: '16px',
            fontFamily: 'Pretendard',
            fontWeight: '400',
            lineHeight: '24px',
            textAlign: 'center'
          }}
        >
          바로 시작하기
        </button>
      </div>
    </div>
  );
};

export default Onboarding;