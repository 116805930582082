import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ResponsiveRouter from './components/ResponsiveRouter';
import { AuthProvider } from './contexts/AuthContext';  

// 페이지 컴포넌트들을 import
import Onboarding from './pages/Onboarding';
import MobileHome from './pages/MobileHome';
import Information from './pages/Information';
import MobileQnA from './pages/MobileQnA';
import Social from './pages/Social';
import Home from './pages/Home';
import QnA from './pages/QnA';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import MyPage from './pages/MyPage';
import NotFound from './pages/NotFound';
import WritePost from './pages/WritePost';
import DesktopInformation from './pages/DesktopInformation';
import DesktopSocial from './pages/DesktopSocial';
import HotGround from './pages/HotGround';
import PlayGround from './pages/PlayGround';
import Visa from './pages/Visa';
import Job from './pages/Job';
import Language from './pages/Language';
import Life from './pages/Life';
import Event from './pages/Event';

const routes = {
  public: [
    { path: '/login', component: Login },
    { path: '/signup', component: SignUp },
    { path: '/not-found', component: NotFound }
  ],
  mobile: [
    { path: '/', component: Onboarding },
    { path: '/mobile-home', component: MobileHome },
    { path: '/info', component: Information },
    { path: '/mobile-qna', component: MobileQnA },
    { path: '/social', component: Social },
    { path: '/home', component: Home },
    { path: '/qna', component: QnA },
    { path: '/my', component: MyPage },
    { path: '/write/:type', component: WritePost } 
  ],
  desktop: [
    { path: '/desktop-information', component: DesktopInformation },
    { path: '/desktop-social', component: DesktopSocial },
    { path: '/hot-ground', component: HotGround },
    { path: '/play-ground', component: PlayGround },
    { path: '/visa', component: Visa },
    { path: '/job', component: Job },
    { path: '/language', component: Language },
    { path: '/life', component: Life },
    { path: '/event', component: Event }
  ]
};

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ResponsiveRouter routes={routes} />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;