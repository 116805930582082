// services/api.js
import axios from 'axios';

const API_URL = 'https://api.koreamate.net';

const api = axios.create({
  baseURL: API_URL,
});

// Request interceptor
api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem('refreshToken');
      const { data } = await axios.post(`${API_URL}/users/api/token/refresh/`, {
        refresh: refreshToken,
      });
      localStorage.setItem('accessToken', data.access);
      api.defaults.headers.Authorization = `Bearer ${data.access}`;
      return api(originalRequest);
    }
    return Promise.reject(error);
  }
);

// 기존 함수들 유지
export const checkEmail = async (email) => {
  const response = await axios.post(`${API_URL}/users/check`, { email });
  return response.data;
};

export const sendVerificationEmail = async (email) => {
  const response = await axios.post(`${API_URL}/users/sendemail`, { email });
  return response.data;
};

export const verifyEmail = async (email, code) => {
  const response = await axios.post(`${API_URL}/users/verify-email`, { email, code });
  return response.data;
};

export const signUp = async (userData) => {
  const response = await axios.post(`${API_URL}/users/signup`, userData);
  return response.data;
};

export const checkNickname = async (nickname) => {
  const response = await axios.post(`${API_URL}/users/check`, { nickname });
  return response.data;
};

export const login = async (loginData) => {
  const response = await axios.post(`${API_URL}/users/login`, loginData);
  return response.data;
};

// 새로운 게시글 관련 함수들
export const postAPI = {
  createPost: async (postData) => {
    const formData = new FormData();
    
    formData.append('post_type', postData.type);
    formData.append('category', postData.category);
    formData.append('title', postData.title);
    formData.append('content', postData.content);
    
    if (postData.sub_category) {
      formData.append('sub_category', postData.sub_category);
    }
    
    if (postData.images?.length > 0) {
      postData.images.forEach(image => {
        formData.append('uploaded_images', image);
      });
    }
    
    const response = await api.post('/posts/', formData);
    return response.data;
  },

  getPosts: async (params) => {
    const response = await api.get('/posts/', { params });
    return response.data;
  },

  getPost: async (id) => {
    const response = await api.get(`/posts/${id}/`);
    return response.data;
  },

  uploadImage: async (formData) => {
    const response = await api.post('/posts/upload-image/', formData);
    return response.data;
  }
};

// 댓글 관련 함수들
export const commentAPI = {
  getComments: async (postId) => {
    const response = await api.get(`/posts/${postId}/comments/`);
    return response.data;
  },

  createComment: async (postId, content) => {
    const response = await api.post(`/posts/${postId}/comments/`, { content });
    return response.data;
  },

  updateComment: async (commentId, content) => {
    const response = await api.put(`/comments/${commentId}/`, { content });
    return response.data;
  },

  deleteComment: async (commentId) => {
    const response = await api.delete(`/comments/${commentId}/`);
    return response.data;
  }
};

// Request interceptor
api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem('refreshToken');
      const { data } = await axios.post(`${API_URL}/users/api/token/refresh/`, {
        refresh: refreshToken,
      });
      localStorage.setItem('accessToken', data.access);
      api.defaults.headers.Authorization = `Bearer ${data.access}`;
      return api(originalRequest);
    }
    return Promise.reject(error);
  }
);

// Auth related APIs
export const authService = {
  checkEmail: async (email) => {
    const response = await axios.post(`${API_URL}/users/check`, { email });
    return response.data;
  },

  sendVerificationEmail: async (email) => {
    const response = await axios.post(`${API_URL}/users/sendemail`, { email });
    return response.data;
  },

  verifyEmail: async (email, code) => {
    const response = await axios.post(`${API_URL}/users/verify-email`, { email, code });
    return response.data;
  },

  signUp: async (userData) => {
    const response = await axios.post(`${API_URL}/users/signup`, userData);
    return response.data;
  },

  checkNickname: async (nickname) => {
    const response = await axios.post(`${API_URL}/users/check`, { nickname });
    return response.data;
  },

  login: async (loginData) => {
    const response = await axios.post(`${API_URL}/users/login`, loginData);
    return response.data;
  },
};

// Post related APIs
export const postService = {
  createPost: async (postData) => {
    const formData = new FormData();
    
    // 기본 데이터 추가
    formData.append('post_type', postData.type);
    formData.append('category', postData.category);
    formData.append('title', postData.title);
    formData.append('content', postData.content);
    
    if (postData.sub_category) {
      formData.append('sub_category', postData.sub_category);
    }
    
    // 이미지 파일들 추가
    if (postData.images?.length > 0) {
      postData.images.forEach(image => {
        formData.append('uploaded_images', image);
      });
    }
    
    const response = await api.post('/posts/', formData);
    return response.data;
  },

  getPosts: async (params) => {
    const response = await api.get('/posts/', { params });
    return response.data;
  },

  getPost: async (id) => {
    const response = await api.get(`/posts/${id}/`);
    return response.data;
  },

  updatePost: async (id, postData) => {
    const response = await api.put(`/posts/${id}/`, postData);
    return response.data;
  },

  deletePost: async (id) => {
    const response = await api.delete(`/posts/${id}/`);
    return response.data;
  },

  // 이미지 업로드 (TUI 에디터용)
  uploadImage: async (formData) => {
    const response = await api.post('/posts/upload-image/', formData);
    return response.data;
  },

  // 검색
  search: async (query) => {
    const response = await api.get('/search/', { params: { query } });
    return response.data;
  },
};

// Comment related APIs
export const commentService = {
  getComments: async (postId) => {
    const response = await api.get(`/posts/${postId}/comments/`);
    return response.data;
  },

  createComment: async (postId, content) => {
    const response = await api.post(`/posts/${postId}/comments/`, { content });
    return response.data;
  },

  updateComment: async (commentId, content) => {
    const response = await api.put(`/comments/${commentId}/`, { content });
    return response.data;
  },

  deleteComment: async (commentId) => {
    const response = await api.delete(`/comments/${commentId}/`);
    return response.data;
  },
};

export default api;