// components/BottomNavigation.js
import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { Home, Info, Users, User } from 'lucide-react';
import { ReactComponent as QnaIcon } from '../assets/icons/bottomNavigation/home.svg';

const NavigationContainer = styled.nav`
  width: 100%;
  height: 65px;
  position: absolute;  // fixed에서 absolute로 변경
  bottom: 0;
  left: 0;
  background: white;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.10);
  border-top: 1px solid #F1F1F5;
`;


const NavItems = styled.div`
  max-width: 420px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
  position: relative;
`;

const NavItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  margin-top: ${({ isCenter }) => (isCenter ? '-16px' : '0')}; // 조건부 스타일링
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px; // 아이콘과 텍스트 사이 간격 조정
  color: ${props => props.isActive ? 'black' : '#D6D6D6'};
`;

const NavText = styled.span`
  color: ${props => props.isActive ? 'black' : '#D6D6D6'};
  font-size: 10px;
  font-family: 'Pretendard', sans-serif;
  font-weight: 600;
  margin-top: 2px;
`;

const CenterButton = styled.div`
  width: 51px;
  height: 51px;
  background: #BC2649;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

// const QnaIcon = styled.div`
//   width: 15px;
//   height: 15px;
//   border: 2px solid white;
// `;

const BottomNavigation = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <NavigationContainer>
      <NavItems>
      <NavItem to="/home">
    <IconWrapper isActive={currentPath === '/home'}>
      <Home size={20} />
    </IconWrapper>
    <NavText isActive={currentPath === '/home'}>Home</NavText>
  </NavItem>
        
        {/* 나머지 네비게이션 아이템들은 그대로 유지 */}
        <NavItem to="/info">
          <IconWrapper isActive={currentPath === '/info'}>
            <Info size={20} />
          </IconWrapper>
          <NavText isActive={currentPath === '/info'}>Info</NavText>
        </NavItem>
        
        <NavItem to="/qna" isCenter>
          <CenterButton>
            <QnaIcon />
          </CenterButton>
        </NavItem>
        
        <NavItem to="/social">
          <IconWrapper isActive={currentPath === '/social'}>
            <Users size={20} />
          </IconWrapper>
          <NavText isActive={currentPath === '/social'}>Social</NavText>
        </NavItem>
        
        <NavItem to="/my">
          <IconWrapper isActive={currentPath === '/my'}>
            <User size={20} />
          </IconWrapper>
          <NavText isActive={currentPath === '/my'}>My</NavText>
        </NavItem>
      </NavItems>
    </NavigationContainer>
  );
};

export default BottomNavigation;