import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Routes, Route } from 'react-router-dom';
import MobileLayout from './layouts/MobileLayout';
import { ReactComponent as AppPreview } from '../assets/icons/desktopOnboarding/mate.svg';

const ResponsiveRouter = ({ routes }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  if (!routes?.mobile?.length) {
    return null;
  }

  const RootComponent = routes.mobile.find(route => route.path === '/')?.component || routes.mobile[0].component;

  const AppContent = () => (
    <Routes>
      {routes.public?.map((route) => (
        <Route key={route.path} path={route.path} element={<route.component />} />
      ))}
      <Route element={<MobileLayout />}>
        <Route index element={<RootComponent />} />
        {routes.mobile.filter(route => route.path !== '/').map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<route.component />}
          />
        ))}
      </Route>
    </Routes>
  );

  return isDesktop ? (
    <div className="fixed inset-0 flex overflow-hidden">
      <div
        className="absolute inset-0"
        style={{
          background: 'linear-gradient(180deg, #BC2649 0%, #EB3660 100%)',
          zIndex: -1,
        }}
      />

      <div className="flex flex-col w-3/5">
        <div className="text-white text-xl font-bold p-[50px]">
          Korea Mate
        </div>

        <div className="pl-[450px] -mt-10">
          <h1 className="text-[60px] font-bold leading-tight text-white">
            From an alien<br/>
            to becoming<br/>
            Korean
          </h1>

          <div className="mt-10">
            <p className="text-[20px] font-bold text-white">
              한국 정착을 위한,<br/>
              Korea Mate만의 특별한 제안!
            </p>

            <p className="mt-10 text-[15px] font-medium leading-[22px] text-white max-w-[395px]">
              'Korea Mate'는 외국인이 한국에서 느낄 수 있는 외로움이나 불편함을 조금이라도 덜어주고, 그들의 든든한 친구가 되고 싶었습니다.
            </p>
          </div>

          <div className="mt-10">
            <div className="flex gap-5 max-w-[391px]">
              <div className="w-[190px] h-[175px] border-2 border-white/50 rounded-[20px] p-[25px]">
                <h3 className="text-[20px] font-bold text-center text-white">Information</h3>
                <p className="mt-[22px] text-[15px] font-medium leading-[22.5px] text-center text-white">
                  커뮤니티에 대한 최신 소식과 유용한 정보를 확인하세요.
                </p>
              </div>
              
              <div className="w-[190px] h-[175px] border-2 border-white/50 rounded-[20px] p-[25px]">
                <h3 className="text-[20px] font-bold text-center text-white">Q</h3>
                <p className="mt-[22px] text-[15px] font-medium leading-[22.5px] text-center text-white">
                  질문과 답변을 통해 커뮤니티와 소통하고 궁금증을 해결하세요.
                </p>
              </div>
            </div>

            <div className="flex gap-5 mt-5 max-w-[391px]">
              <div className="w-[190px] h-[175px] border-2 border-white/50 rounded-[20px] p-[25px]">
                <h3 className="text-[20px] font-bold text-center text-white">Board</h3>
                <p className="mt-[22px] text-[15px] font-medium leading-[22.5px] text-center text-white">
                  자유롭게 게시글을 올리고 다양한 의견을 나누세요.
                </p>
              </div>
              <div className="w-[190px] h-[175px] flex items-center justify-center">
                <AppPreview />
              </div>
            </div>
          </div>
        </div>

        {/* 하단 앱 다운로드 - 왼쪽 하단 */}
        <div className="absolute bottom-[50px] left-[50px]">
          <div className="mb-4 px-4 py-[10px] bg-white rounded-[20px] shadow-[inset_0px_-2px_4px_rgba(0,0,0,0.25)] inline-flex justify-center items-center">
            <span className="text-[12px] font-bold text-primary">앱을 다운로드 받으세요!</span>
          </div>
          <div>
            <img src={require('../assets/icons/desktopOnboarding/stores.png')} alt="App Stores" className="cursor-pointer" />
          </div>
        </div>
      </div>

      {/* 오른쪽 모바일 앱 영역 */}
      <div className="w-[420px] h-screen shrink-0 relative">
        <div className="relative w-full h-full bg-white rounded-lg shadow-[0_0_20px_rgba(0,0,0,0.1)] border border-[#FFE4E9] overflow-hidden">
          <div className="h-full overflow-y-scroll scrollbar-hide">
            <AppContent />
          </div>
        </div>
      </div>

      <div className="flex-1" />
    </div>
  ) : (
    <div className="min-h-screen bg-white">
      <AppContent />
    </div>
  );
};

export default ResponsiveRouter;