import React from 'react';

const Information = () => {
  return (
    <div className="information">
      <h1>Information</h1>
      <p>Find all the information you need here.</p>
      {/* Add information categories or search functionality */}
    </div>
  );
};

export default Information;