import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import BottomNavigation from '../BottomNavigation';

const MobileLayout = () => {
  const location = useLocation();
  const hideNavigationPaths = ['/', '/login', '/signup'];
  const shouldHideNavigation = hideNavigationPaths.includes(location.pathname);

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 overflow-y-auto">
        <Outlet />
      </div>
      
      {!shouldHideNavigation && (
        <div className="flex-shrink-0">
          <BottomNavigation />
        </div>
      )}
    </div>
  );
};

export default MobileLayout;