import React from 'react';
import styled from 'styled-components';
import { ReactComponent as KakaoIcon } from '../assets/icons/kakao.svg';
import { ReactComponent as GoogleIcon } from '../assets/icons/google.svg';
import { ReactComponent as FacebookIcon } from '../assets/icons/facebook.svg';
import { ReactComponent as AppleIcon } from '../assets/icons/apple.svg';
import { ReactComponent as KoreaMateTextLogo } from '../assets/icons/KoreaMateTextLogo.svg';
import { ReactComponent as KoreaMateSymbolLogo } from '../assets/icons/KoreaMateSymbolLogo.svg';
import { useNavigate } from 'react-router-dom';

const OnboardingContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  box-sizing: border-box;
`;

const TopSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoginSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
`;

const LoginDivider = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const DividerLine = styled.div`
  width: 92px;
  height: 1px;
  background: #EEEEEE;
`;

const DividerText = styled.div`
  margin: 0 10px;
  color: black;
  font-size: 12px;
  font-family: 'Pretendard', sans-serif;
  font-weight: 400;
`;

const SocialLoginContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 260px;
  margin-bottom: 20px;
`;

const SocialLoginButton = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.bgColor || 'transparent'};
  border: ${props => props.border || 'none'};
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
  }
`;

const StartButton = styled.button`
  width: 100%;
  max-width: 324px;
  height: 42px;
  background: #BC2649;
  border-radius: 5px;
  border: none;
  color: white;
  font-size: 16px;
  font-family: 'Pretendard', sans-serif;
  font-weight: 500;
  cursor: pointer;
`;

const TextLogo = styled.div`
  width: 225px;
  margin-bottom: 40px;
  svg {
    width: 100%;
    height: auto;
  }
`;

const SymbolLogo = styled.div`
  width: 127.56px;
  svg {
    width: 100%;
    height: auto;
  }
`;

const Login = () => {
  const navigate = useNavigate();

  const handleStartClick = () => {
    navigate('/mobile-home');  // 또는 원하는 경로
  };
  return (
    <OnboardingContainer>
      <TopSection>
        <TextLogo>
          <KoreaMateTextLogo />
        </TextLogo>
        <SymbolLogo>
          <KoreaMateSymbolLogo />
        </SymbolLogo>
      </TopSection>
      <LoginSection>
        <LoginDivider>
          <DividerLine />
          <DividerText>SNS 계정으로 로그인</DividerText>
          <DividerLine />
        </LoginDivider>
        <SocialLoginContainer>
          <SocialLoginButton bgColor="#FEE500">
            <KakaoIcon />
          </SocialLoginButton>
          <SocialLoginButton bgColor="#1877F2">
            <FacebookIcon />
          </SocialLoginButton>
          <SocialLoginButton bgColor="white" border="1px solid #E2E2E2">
            <GoogleIcon />
          </SocialLoginButton>
          <SocialLoginButton bgColor="#000000">
            <AppleIcon />
          </SocialLoginButton>
        </SocialLoginContainer>
        <StartButton onClick={handleStartClick}>바로 사용하기</StartButton>
      </LoginSection>
    </OnboardingContainer>
  );
};

export default Login;