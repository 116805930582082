import React from 'react';

const MyPage = () => {
  return (
    <div className="my-page">
      <h1>My Page</h1>
      <p>Manage your account and settings here.</p>
      {/* Add user profile and settings */}
    </div>
  );
};

export default MyPage;