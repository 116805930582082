import React, { useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import { Editor } from '@toast-ui/react-editor';
import { postService } from '../services/api';
import '@toast-ui/editor/dist/toastui-editor.css';

const WritePost = () => {
  const navigate = useNavigate();
  const { type } = useParams();  // 'social' 또는 'info'
  const [title, setTitle] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const editorRef = useRef(null);

  // 카테고리 옵션 설정
  const categoryOptions = type === 'social' ? [
    { value: 'VISA', label: '비자' },
    { value: 'WORK', label: '노동' },
    { value: 'HOME', label: '주거' },
  ] : [
    { value: 'HOT', label: 'Hot ground' },
    { value: 'PLAY', label: 'Play ground' },
    { value: 'VISA', label: 'VISA' },
    { value: 'JOB', label: 'JOB' },
    { value: 'LANG', label: 'Language' },
    { value: 'LIFE', label: 'Life' },
    { value: 'EVENT', label: 'Event' },
    { value: 'QA', label: 'Q&A' },
  ];

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const editorInstance = editorRef.current.getInstance();
      const content = editorInstance.getMarkdown();
      
      if (!title.trim() || !content.trim() || !selectedCategory) {
        alert('제목, 내용, 카테고리를 모두 입력해주세요.');
        return;
      }

      const postData = {
        type: type.toUpperCase(),
        category: selectedCategory,
        title,
        content
      };

      await postService.createPost(postData);
      navigate(`/${type}`);
    } catch (error) {
      console.error('Error creating post:', error);
      alert('게시글 작성 중 오류가 발생했습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageUpload = async (blob, callback) => {
    try {
      const formData = new FormData();
      formData.append('image', blob);
      
      // 이미지 업로드 API 호출
      const response = await postService.uploadImage(formData);
      callback(response.data.url);
    } catch (error) {
      console.error('Error uploading image:', error);
      callback('');
    }
  };

  return (
    <div className="w-full max-w-[420px] mx-auto flex flex-col h-screen bg-white">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-white border-b">
        <div className="flex items-center justify-between p-4">
          <div className="flex items-center">
            <button onClick={() => navigate(-1)} className="mr-2">
              <ChevronLeft className="w-6 h-6" />
            </button>
            <span className="text-lg">글쓰기</span>
          </div>
        </div>
      </div>

      {/* Category Selection */}
      <div className="p-4 border-b">
        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          className="w-full p-2 border rounded-md"
        >
          <option value="">카테고리 선택</option>
          {categoryOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        
        {type === 'social' && (
          <input
            type="text"
            placeholder="세부 카테고리"
            value={subCategory}
            onChange={(e) => setSubCategory(e.target.value)}
            className="w-full p-2 mt-2 border rounded-md"
          />
        )}
      </div>

      {/* Title Input */}
      <div className="p-4 border-b">
        <input
          type="text"
          placeholder="제목을 입력하세요"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full text-xl focus:outline-none"
          maxLength={200}
        />
      </div>

      {/* Editor */}
      <div className="flex-1 overflow-hidden">
        <Editor
          ref={editorRef}
          initialValue=""
          placeholder="내용을 입력하세요"
          previewStyle="vertical"
          height="100%"
          initialEditType="wysiwyg"
          useCommandShortcut={true}
          hooks={{
            addImageBlobHook: handleImageUpload
          }}
        />
      </div>

      {/* Submit Button */}
      <div className="p-4 border-t">
        <button
          onClick={handleSubmit}
          disabled={isLoading}
          className="w-full py-3 text-white bg-[#BC2649] rounded-md disabled:bg-gray-300"
        >
          {isLoading ? '등록 중...' : '등록하기'}
        </button>
      </div>
    </div>
  );
};

export default WritePost;