import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
`;

const PostList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
`;

const Post = styled.div`
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const PostTitle = styled.h2`
  font-size: 18px;
  color: #444;
  margin-bottom: 10px;
`;

const PostContent = styled.p`
  font-size: 14px;
  color: #666;
`;

const DesktopSocial = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    // 여기서 실제로는 API를 호출하여 게시물 데이터를 가져와야 합니다.
    // 예시를 위해 더미 데이터를 사용합니다.
    const dummyPosts = [
      { id: 1, title: "한국 생활 팁", content: "한국에서 생활하면서 알게 된 유용한 팁들을 공유합니다." },
      { id: 2, title: "주말 여행 추천", content: "서울 근교의 아름다운 여행지를 소개합니다." },
      { id: 3, title: "한국 음식 도전기", content: "처음 먹어본 한국 음식들에 대한 솔직한 후기!" },
      { id: 4, title: "언어 교환 파트너 찾습니다", content: "한국어를 배우고 영어를 가르쳐줄 수 있는 파트너를 찾고 있어요." },
      { id: 5, title: "문화 충격 극복하기", content: "문화 차이로 인한 어려움을 극복한 경험을 나눕니다." },
      { id: 6, title: "한국 직장 생활 적응기", content: "외국인으로서 한국 회사에서 일하면서 겪은 이야기들" },
    ];
    setPosts(dummyPosts);
  }, []);

  return (
    <Container>
      <Title>소셜 커뮤니티</Title>
      <PostList>
        {posts.map(post => (
          <Post key={post.id}>
            <PostTitle>{post.title}</PostTitle>
            <PostContent>{post.content}</PostContent>
          </Post>
        ))}
      </PostList>
    </Container>
  );
};

export default DesktopSocial;