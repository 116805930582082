import React from 'react';
import { Link } from 'react-router-dom';

const MobileHome = () => {
  return (
    <div className="mobile-home">
      <h1>Mobile Home</h1>
      <nav>
        <ul>
          <li><Link to="/information">Information</Link></li>
          <li><Link to="/qna">Q&A</Link></li>
          <li><Link to="/social">Social</Link></li>
          <li><Link to="/mypage">My Page</Link></li>
        </ul>
      </nav>
      <div className="featured-content">
        <h2>Featured Content</h2>
        {/* Add featured content here */}
      </div>
    </div>
  );
};

export default MobileHome;