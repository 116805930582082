// components/PostItem.js
import React from 'react';

const PostItem = ({ post }) => {
  return (
    <div className="px-4 py-3 bg-white flex items-center gap-2">
      <img 
        src={post.image} 
        alt={post.title}
        className="w-[70px] h-[40px] rounded-[5px] object-cover"
      />
      <div className="flex-1 flex flex-col gap-2">
        <div className="flex items-center gap-1.5">
          {post.isHot && (
            <div className="px-1.5 bg-[#BC2649] rounded-[2px] flex items-center">
              <span style={{
                color: 'white',
                fontSize: '10px',
                fontFamily: 'Pretendard',
                fontWeight: '600',
                lineHeight: '16px',
              }}>HOT</span>
            </div>
          )}
          <h3 style={{
            color: '#292929',
            fontSize: '14px',
            fontFamily: 'Pretendard',
            fontWeight: '500',
            lineHeight: '20px',
            wordWrap: 'break-word',
          }} className="flex-1">
            {post.title}
          </h3>
        </div>

        <div className="flex items-center gap-1.5">
          {/* Likes */}
          <div className="flex items-center gap-0.5">
            <div className="w-3 h-3 rotate-180">
              <div className="w-[9.73px] h-[10px] border border-[#FC006A]" />
            </div>
            <span style={{
              color: '#FC006A',
              fontSize: '11px',
              fontFamily: 'Pretendard',
              fontWeight: '400',
              lineHeight: '12px',
            }}>{post.likes}</span>
          </div>

          {/* Comments */}
          <div className="flex items-center gap-0.5">
            <div className="w-3 h-3 rotate-180">
              <div className="w-[9px] h-[9px] border border-[#38B4A6]" />
            </div>
            <span style={{
              color: '#38B4A6',
              fontSize: '11px',
              fontFamily: 'Pretendard',
              fontWeight: '400',
              lineHeight: '12px',
            }}>{post.comments}</span>
          </div>

          {/* Divider */}
          <div className="w-px h-3 bg-[#F1F1F5]" />

          {/* Time */}
          <span style={{
            color: '#A6A6A6',
            fontSize: '11px',
            fontFamily: 'Pretendard',
            fontWeight: '400',
            lineHeight: '12px',
          }}>{post.time}</span>

          {/* Divider */}
          <div className="w-px h-3 bg-[#F1F1F5]" />

          {/* Author */}
          <div className="flex items-center gap-0.5">
            <img src={post.authorIcon} alt="" className="w-3 h-3" />
            <span style={{
              color: '#A6A6A6',
              fontSize: '11px',
              fontFamily: 'Pretendard',
              fontWeight: '400',
              lineHeight: '12px',
            }}>{post.author}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostItem; 